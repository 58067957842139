<template>
    <div class="download-page">
        <div class="wrap">
            <div class="box page-title">
                <a class="item" href="javascript:void(0)" @click="toUrl('/downloadAcr')">ACR预设</a>
                <a class="item active" href="javascript:void(0)" @click="toUrl('/downloadSoftware')">软件</a>
                <a class="item" href="javascript:void(0)" @click="toUrl('/downloadMaterial')">素材</a>
            </div>
            <div class="box main-con">
                <div class="list">
                    <div class="item z-flex z-flex-between" v-for="(item,index) in list" :key="index" @click="toDetail(item)">
                        <img class="img" :src="item.thumb"/>
                        <div class="text">
                            <div class="title">{{item.title}}</div>
                            <div class="date">发布时间：<span>{{item.published_at}}</span></div>
                            <div class="free">{{item.type}}</div>
                            <div class="desc">{{item.short_desc}}</div>
                        </div>
                        <el-button-group class="btn-group">
                            <el-button v-if="item.url" class="btn" @click.stop="down(item.url)">网盘下载</el-button>
                            <el-button v-if="item.mac_down_url" class="btn" @click.stop="vipDown(item.mac_down_url)">
                                <i class="iconfont el-icon--left">&#xe881;</i>
                                MAC版下载
                                <div class="vip-label"><span>VIP</span></div>
                            </el-button>
                            <el-button v-if="item.win_down_url" class="btn" @click.stop="vipDown(item.win_down_url)">
                                <i class="iconfont el-icon--left">&#xe882;</i>
                                WIN版下载
                                <div class="vip-label"><span>VIP</span></div>
                            </el-button>
                        </el-button-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    export default {
        components: {
            
        },
        computed: {
            ...mapState(['vuex_user'])
        },
        data() {
            return {
                list:[
                    {
                        img:'https://dyyxclub-files.oss-cn-hangzhou.aliyuncs.com/images/xA4QMWNEt8gTvN4tlkq8hR3lijt4TnxyYFYGl5eK.jpeg',
                        title:'Adobe Photoshop 2022+Win ACR14.0',
                        date:'2021-10-21',
                        free:true,
                        desc:'Adobe Photoshop 2022（仅限WIN10及更高版本用户使用）Adobe Photoshop 2021（仅限MAC10.15及更高版本用户使用）'
                    },{
                        img:'https://dyyxclub-files.oss-cn-hangzhou.aliyuncs.com/images/9JtbHYRthDkJDC7ottdjYW2YriDEUlXXG4dK8KeZ.jpeg',
                        title:'Adobe Photoshop 2022+Win ACR14.0',
                        date:'2021-10-21',
                        free:true,
                        desc:'Adobe Photoshop 2022（仅限WIN10及更高版本用户使用）Adobe Photoshop 2021（仅限MAC10.15及更高版本用户使用）'
                    },
                ],
            };
        },
        mounted() {
            this.$axios.api.downSoftware({}, (res) => {
                if (res.code == 0) {
                    this.list = res.data.list;
                } else {
                    console.log(res.msg);
                }
            });
        },
        methods: {
            toUrl(url){
                this.$router.push({
                    path:url
                })
            },
            toDetail(item){
                this.$router.push({
                    path:'/downDetail/'+item.id
                })
            },
            down(url){
                if (!this.vuex_user) {
                    //登录窗口
                    return this.$parent.$refs.navcom.login();
                }
                var a = document.createElement('a');
                a.href = url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            },
            vipDown(url){
                if (!this.vuex_user) {
                    //登录窗口
                    return this.$parent.$refs.navcom.login();
                }
                if (!this.vuex_user.is_role) {
                    //必须是VIP才能下载
                    return this.$message.error("此文件VIP用户才能下载");
                }
                this.$axios.api.ossDown({url: url}, (res) => {
                    if (res.code == 0) {
                        var a = document.createElement('a');
                        a.href = res.data.signurl;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    } else {
                        console.log(res.msg);
                    }
                });
            }
        },
    };
</script>

<style lang="scss" scoped>
.box{
    width:calc(100% - 60px);
    padding:20px 30px;
    background:#fff;
    border-radius: 4px;
    margin-top: 10px;
}   
.page-title{
    margin-top: 30px;
    .item{
        font-size: 18px;
        padding: 0 20px;
        margin:0 20px;
        &.active{
            color:$colorMain;
        }
        &:hover{
            color:$colorMain;
        }
    }
}
.main-con{
    .acr-title{
        margin: 0 10px 10px 0;
    }
}
.list{
    .item{
        padding:40px 0;
        border-bottom: $borderDashed;
        align-items:flex-start;
        position: relative;
        cursor: pointer;
        .img{
            width: 180px;
            height: 180px;
            background: #eaedee;
            border-radius: 8px; 
        }
        .text{
            width:calc(100% - 210px);
            .title{
                font-weight: bold;
                color: #474747;
                font-size: 20px;
                max-width: 550px;
                @extend %textOverflow;
                margin-bottom: 10px;
            }
            .date{
                color: #ccc;
                font-size: 16px;
                span{
                    color:$colorMain;
                }
            }
            .free{
                font-size: 24px;
                color: #ff3738;
                font-weight: 700;
                line-height: 50px;
            }
            .desc{
                color: #999;
                font-size: 16px;
                @include textOverflowNum(2)
            }
        }
        .btn-group{
            position:absolute;
            right:0;
            top: 40px;
            .btn{
                position: relative;
                .vip-label{
                    position: absolute;
                    right: 0;
                    top: 0;
                    font-size:12px;
                    width: 50px;
                    height: 50px;
                    overflow: hidden;
                    position: absolute;
                    span{
                        display: block;
                        text-align: center;
                        transform: rotate(40deg);
                        position: relative;
                        padding: 2px 0;
                        right: -15px;
                        top: 2px;
                        width: 50px;
                        background: #E6A23C;
                        color: white;
                        letter-spacing: 1px;
                        font-weight: 700;
                    }
                }
            }
        }
        &:hover{
            .title{
                color:$colorMain;
            }
        }
    }
}
</style>
